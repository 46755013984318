import React, { useState } from 'react';
import { Link }  from "react-router-dom";
import "../styles/Home.css"
import axios from 'axios';


const Support = () => {

    const [categoryText, setCategoryText] = useState("Support");
    const [pageText, setPageText] = useState("");
    const [nameText, setNameText] = useState("");
    const [emailText, setEmailText] = useState("");
    const [subjectText, setSubjectText] = useState("");
    const [contentText, setContentText] = useState("");

    const submitOnClick = (event) => {

        axios.post(process.env.REACT_APP_BASE_API_URL + "api/ServiceRequests", {    
            category: categoryText,
            customerName: nameText,
            customerEmailAddress: emailText,
            subject: subjectText,
            message: contentText,
            page: pageText
         }).then((response) => {
            
            const form = document.getElementById('contact-form');
            form.className = 'hidden';

            const confirmation = document.getElementById('confirmation');
            confirmation.className = '';
            confirmation.innerHTML = '<h1>Form Submission Received!</h1>';
            confirmation.innerHTML += `<h2>Ticket Number: ${response.data.ticketId} </h2>`

         }).catch((response) => {
            console.log(response);
            alert("Could not submit the form at this time. Please try again later.");
         })

         event.preventDefault();

    }

    const categoryTextOnChange = (e) => {
        setCategoryText(e.target.value)
    }
  
    const pageTextOnChange = (e) => {
        setPageText(e.target.value)
    }

    const nameTextOnChange = (e) => {
        setNameText(e.target.value)
    }

    const emailTextOnChange = (e) => {
        setEmailText(e.target.value)
    }
  
    const subjectTextOnChange = (e) => {
        setSubjectText(e.target.value)
    }

    const contentTextOnChange = (e) => {
        setContentText(e.target.value)
    }

    return (

        <div>

            <div id='contact-form'>
                <div className="flex flex-col items-center">
                    <h1>Contact Form</h1>
                </div>
            
                <form id='form' className="w-1/2 ml-96 pl-20" >
                

                    <div className="mt-1 mb-1">

                        Category: <select id="category" className="ml-4" placeholder="Category" onChange={categoryTextOnChange} value={categoryText} required>
                            <option value="Support">Support</option>
                            <option value="Feedback">Suggestions & Improvements</option>
                            <option value="Bug">Bug Feedback</option>
                        </select>
                    </div>

                    <div className="mb-1">

                        Page: <select id="page" default-value="" className="ml-12" placeholder="Page" onChange={pageTextOnChange} value={pageText}>
                            <option disabled selected value></option>
                            <option value="Home">Home</option>
                            <option value="Preferences">Preferences</option>
                            <option value="Restaurant History">Restaurant History</option>
                            <option value="Contact">Contact</option>
                            <option value="User Settings">User Settings</option>

                        </select>
                    </div>

                    <div className="mb-1">
                        Name: <input type="text" size="25" className="ml-10" placeholder="Name" onChange={nameTextOnChange} value={nameText} required/>
                    </div>

                    <div className="mb-1">
                        Email: <input type="text" size="25" className="ml-11" placeholder="Email" onChange={emailTextOnChange} value={emailText} required/>
                    </div>


                    <div className="mb-1">
                        Subject: <input type="text" size="50" className="ml-7" placeholder="Subject" onChange={subjectTextOnChange} value={subjectText}/>
                    </div>


                    <div className="mb-1">
                        <textarea name="" id="" cols="60" rows="10" laceholder="Content" onChange={contentTextOnChange} value={contentText} required></textarea>
                    </div>

                    <div className="mb-1 flex items-center justify-center bg-yellow-500 rounded-md h-7 w-2/5 text-white mr-2 mt-1 hover:bg-yellow-600">
                        <input id='submit' type="submit" onClick={submitOnClick}></input>
                    </div>

                    <div className="mb-1 flex items-center justify-center bg-yellow-500 rounded-md h-7 w-2/5 text-white mr-2 mt-1 hover:bg-yellow-600">
                        <Link to="/"><button>Return Home</button></Link>
                    </div>
                </form>

            </div>

            <div id='confirmation' className='hidden'></div>
        </div>

    );

}

export default Support;