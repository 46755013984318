import React, { useState, useRef, useEffect, useMemo } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, MarkerClustererF, OverlayViewF } from '@react-google-maps/api';
import { useSessionStorage } from '../utils/useSessionStorage';
import { useLocalStorage } from '../utils/useLocalStorage';
import LocationPermission from './LocationPermission';
import marker from '../assets/map/green-map-marker.png'
import plate from '../assets/images/plate.png'

import LoginButton from '../components/buttons/LoginButton';
import SignupButton from '../components/buttons/SignupButton';

import LoadingIndicator from './LoadingIndicator';
import MapMarker from './icons/MapMarker';
const windowHeight = window.innerHeight;

const containerStyle = {
    width: '100vw',
    height: '100vh',
    zIndex: 0
};

const center = {
    lat: -3.745,
    lng: -38.523
}

const mapOptions = {
    disableDefaultUI: true,
    clickableIcons: false,
    minZoom: 10,
    styles: [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#6195a0"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#e6f3d6"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#f4d2c5"
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text",
            "stylers": [
                {
                    "color": "#4e4e4e"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#f4f4f4"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#787878"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#eaf6f8"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#eaf6f8"
                }
            ]
        }
    ]
}



const Map = ({ restaurants, onMapLoad, filterValues, zoomLevel, moveToUser }) => {

    const [user, setUser] = useSessionStorage("user", null)
    const [userLocation, setUserLocation] = useLocalStorage("userLocation", null)
    const [locationAccess, setLocationAccess] = useSessionStorage("locationAccess", "");
    const [locationFound, setLocationFound] = useState(false);
    const [locations, setLocations] = useState([]);
    const [mapInstance, setMapInstance] = useState(null);
    // const userCoords = useMemo(() => { return { lat: center.lat, lng: center.lng } }, []);
    const [userCoords, setUserCoords] = useState({ lat: center.lat, lng: center.lng })
    const mapRef = useRef(null); 

    function getLocationAccess() {
        navigator.permissions.query({ name: 'geolocation' })
            .then((result) => {
                if (result.state == 'granted') {
                    setLocationAccess('auto')
                    navigator.geolocation.getCurrentPosition((position) => {
                        setUserCoords({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        })

                        setLocationFound(true);
                    })
                } else if (result.state == 'prompt') {
                    setLocationAccess("prompt");
                } else if (result.state == 'denied') {
                    setLocationAccess("manual");
                }
            })
            .catch(() => {
                setLocationAccess("manual");
            });
    }

    const handleMarkerClick = (index) => {
        let correspondingCard = document.getElementById(`restaurant-card-${index}`);
        correspondingCard.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    const handleZoomChanged = () => {
        if (mapRef.current) {
            const zoomLevel = mapRef.current.getZoom();
            console.log('Zoom level:', zoomLevel);
            // Perform any desired actions with the zoom level
        }
    };

    useEffect(() => {
        getLocationAccess()
    }, [locationAccess])

    useEffect(() => {
        console.log(restaurants)
        setLocations(restaurants);
    }, [restaurants])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    });

    const onLoad = React.useCallback((map) => {
        onMapLoad(userCoords)
        mapRef.current = map;
        setMapInstance(map);
    })

    const onUnmount = React.useCallback((map) => {
        mapRef.current = null;
        setMapInstance(null);
    })

    if(moveToUser){
        navigator.geolocation.getCurrentPosition((position) => {
            setUserCoords({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })

            setLocationFound(true);
        })
    }


    return (
        <>
            {
                (locationAccess == 'prompt') &&
                <LocationPermission />
            }
            {
                (locationAccess == 'auto' && isLoaded && locationFound) &&
                <>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        options={mapOptions}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        center={userCoords}
                        zoom={zoomLevel}
                        onZoomChanged={handleZoomChanged}
                    >
                        <MarkerF
                            position={userCoords}
                            icon={{
                                url: user?.pictureUrl || 'https://cdn.iconscout.com/icon/free/png-512/free-avatar-human-man-profile-auto-user-30483.png?f=avif&w=512',
                                scaledSize: new window.google.maps.Size(40, 40)
                            }}
                        />
                        {mapInstance &&
                            locations.length > 0 &&
                            locations.map((restaurant, index) => {
                                return (
                                    <OverlayViewF
                                        key={index}
                                        position={{ lat: restaurant.address.latitude, lng: restaurant.address.longitude }}
                                        mapPaneName={"overlayMouseTarget"}
                                    >
                                        <MapMarker ranking={index+1} restaurant={restaurant}/>
                                    </OverlayViewF>
                                );
                            })}
                    </GoogleMap>
                </>
            }
            {(!locationFound && locationAccess == 'auto') &&
                <LoadingIndicator />
            }
            {
                (locationAccess == 'auto' && !isLoaded) &&
                <p className="text-xl">The map is not loaded</p>

            }
            {
                locationAccess == 'manual' &&
                <div>
                    <p>Please enable location access from user settings to continue</p>
                </div>
            }
            
        </>
    )
}

export default Map;