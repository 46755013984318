import React from 'react';

const CuisineBar = () => {
   return (
      <div className="absolute z-20 top-36 left-6">
         <div className="flex">
            <div className="flex flex-col items-center mr-8">
               <div className="bg-gray-500 w-16 h-12 rounded-full" style={{opacity: 0.75}}>
                  {/* icon here */}
               </div>
               <p className="font-medium mt-1">
                  Mexican
               </p>
            </div>
            <div className="flex flex-col items-center mr-8">
               <div className="bg-gray-500 w-16 h-12 rounded-full" style={{opacity: 0.75}}>
                  {/* icon here */}
               </div>
               <p className="font-medium mt-1">
                  Italian
               </p>
            </div>
            <div className="flex flex-col items-center mr-8">
               <div className="bg-gray-500 w-16 h-12 rounded-full" style={{opacity: 0.75}}>
                  {/* icon here */}
               </div>
               <p className="font-medium mt-1">
                  American
               </p>
            </div>
            <div className="flex flex-col items-center mr-8">
               <div className="bg-gray-500 w-16 h-12 rounded-full" style={{opacity: 0.75}}>
                  {/* icon here */}
               </div>
               <p className="font-medium mt-1">
                  Fast Food
               </p>
            </div>
            <div className="flex flex-col items-center mr-8">
               <div className="bg-gray-500 w-16 h-12 rounded-full" style={{opacity: 0.75}}>
                  {/* icon here */}
               </div>
               <p className="font-medium mt-1">
                  Breakfast
               </p>
            </div>
            <div className="flex flex-col items-center mr-8">
               <div className="bg-gray-500 w-16 h-12 rounded-full" style={{opacity: 0.75}}>
                  {/* icon here */}
               </div>
               <p className="font-medium mt-1">
                  Coffee
               </p>
            </div>
         </div>
      </div>
   )
}

export default CuisineBar;