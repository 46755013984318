import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import '../../styles/styles.css'
import '../../styles/components/restaurantCard.css'

import RankingMarker from "../icons/RankingMarker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import heartIcon from "../../assets/images/heartIcon.svg";


const RestaurantCardScroller = ({ children }) => {


   return (
      <div className="absolute z-20 white-scroller smooth-scroll" style={{ top: '230px', height: 'calc(100vh - 230px)', width: "30%", maxWidth: '450px' }} id="restaurant-card-scroller">
         <div className="flex flex-col justify-center items-center mt-5">
            {children}
         </div>
      </div>
   );
};

const Item = ({ image, name, priceRange, cuisineType, cuisineCategory, rating, ratingCount, distance, match, id, index}) => {
   const [heartFilled, setHeartFilled] = useState(false)
   const [heartClicked, setHeartClicked] = useState(false)
   const [isScrolling, setIsScrolling] = useState(false);

   function selectMatchTextColor(match) {
      if (match >= 90) {
         return " text-green-500 "
      } else if (match >= 50) {
         return " text-purple-500 "
      } else {
         return " text-orange-500 "
      }
   }

   return (
      <div className="restaurant-card" 
            onMouseOver={() => { setIsScrolling(true) }} 
            onMouseLeave={() => { setIsScrolling(false) }}
            id={id}>
         <div className="card-header flex justify-around">
            <div className="card-header-rank">
               <RankingMarker text={index+1} innerColor="bg-green-300" outerColor="bg-green-500" />
            </div>
            <div className="card-header-title">
               <Marquee 
               play={isScrolling} 
               speed={75}
               className="rest-title-marquee"
               >
                  <h4 className="text-4xl font-medium header-font mt-2">{name}&nbsp;</h4>
               </Marquee>
            </div>
            <div className="card-header-options flex flex-col">
               {heartFilled
                  ? <FontAwesomeIcon icon={faHeart} className="hover:cursor-pointer text-red-500 text-xl m-1" onMouseLeave={() => { !heartClicked && setHeartFilled(false) }} onClick={() => { setHeartClicked(!heartClicked) }} />
                  : <img src={heartIcon} className="w-5 h-auto hover:cursor-pointer m-1" onMouseEnter={() => { setHeartFilled(true) }} />}
               <FontAwesomeIcon icon={faEllipsisVertical} className="hover:cursor-pointer" />
            </div>
         </div>
         <div className="card-details">
            <div className="card-details-t ml-2">
               <p className="font-bold text-sm xl:text-base"><span className="text-sm xl:text-base font-normal text-gray-500">{priceRange.length > 0 ? priceRange : "?"}</span> · <span className="text-sm xl:text-base font-normal text-gray-500">{cuisineType}</span> · <span className="text-sm xl:text-base font-normal text-gray-500">{rating ? rating : "?"}</span> <FontAwesomeIcon icon={faStar} /> <span className="text-sm xl:text-base font-normal text-gray-500">({ratingCount})</span> · <span className="text-sm xl:text-base font-normal text-gray-500">{distance}0</span><span> mi</span></p>
            </div>
            <div className="card-details-b ml-2">
               <p className="text-sm xl:text-base under"><span className={"text-sm font-bold mt-1" + selectMatchTextColor(match)}>{match + " %"}</span> match with your <span className="underline">Perfect Plate Profile</span></p>
            </div>
         </div>
         <div className="card-image relative">
            <div id="card-image container">
               <img src={image} alt={name} className="card-image image" />
               <div className="absolute top-4 left-3 rounded-full w-3/12 bg-green-400 text-center text-white border text-sm border-gray-500">
                  High Match
               </div>
            </div>
         </div>
      </div>
   );
};

RestaurantCardScroller.Item = Item;

export default RestaurantCardScroller;