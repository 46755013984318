import React, { useState } from 'react';
import axios from 'axios';
import SignupButton from '../components/buttons/SignupButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useSessionStorage } from '../utils/useSessionStorage';
import homePageLogo from '../assets/images/perfectplatelogo2.png';
import LoginButton from '../components/buttons/LoginButton';
import LoadingIndicator from '../components/LoadingIndicator';

const Login = () => {
   const [emailText, setEmailText] = useState("");
   const [passwordText, setPasswordText] = useState("");
   const [validationTrigger, setValidationTrigger] = useState(false);
   const [user, setUser] = useSessionStorage("user", null)
   const [inputType, setInputType] = useState("password")
   const [passwordVisible, setPasswordVisible] = useState(false);
   const [startLoad, setStartLoad] = useState(false);

   const logInOnClick = () => {
      setStartLoad(true);
      axios.post(process.env.REACT_APP_BASE_API_URL + "api/Users/Login", {
         email: emailText,
         password: passwordText
      }).then((response) => {
         let data = response.data;
         if (data.id <= 0) {
            setValidationTrigger(true)
            // setTimeout(() => {
            //    setValidationTrigger(false);
            // }, 3500)
         } else {
            setUser(data);
            window.location.href = "/main";
         }

         setStartLoad(false);
         console.log(data)
      }).catch(() => {
         alert("Could not log in at this time. Please try again later.")
      })
   }

   const emailTextOnChange = (e) => {
      setEmailText(e.target.value)
   }

   const passwordTextOnChange = (e) => {
      setPasswordText(e.target.value)
   }

   const toggleShowPassword = () => {
      setPasswordVisible(!passwordVisible)
      if (passwordVisible) {
         setInputType("text")
      } else {
         setInputType("password")
      }
   }

   return (
      <div className="px-10 w-full mx-auto bg-white absolute t-0 l-0" style={{ height: '105vh' }}>
         <div className="w-full mx-auto">
            <a href="/"><img className="mx-auto md:w-1/6 w-1/3 h-auto mt-10" src={homePageLogo} /></a>
         </div>
         <div className="md:flex md:flex-row flex-col w-100" id="registration-container">
            <div className='md:w-1/2'>
               <div>
                  <p className="md:text-8xl text-6xl text-black font-bold header-font mt-10">LOGIN</p>
                  <p className="md:text-2xl text-lg md:mt-0 mt-4">New to Perfect Plate? <a href="/register" className="underline font-bold" style={{ color: "#F3A828" }}>Create an account</a></p>
               </div>
               <div id="user-registration">
               { validationTrigger && <p className="md:hidden w-full mt-3 text-center text-md -mb-5 text-red-500">Oops, it looks like your email address or password doesn't match our records.</p> }
                  <div className="flex">
                     <div className="w-full my-5">
                        <p className="font-bold md:text-2xl text-xl mb-3">Email</p>
                        <input type="email" className={"rounded-md md:h-12 h-10 md:w-3/4 w-full p-2 text-lg " + (validationTrigger ? "bg-red-200" : "bg-gray-200")} onChange={emailTextOnChange} value={emailText} />
                     </div>
                  </div>
                  <div>
                     <p className="font-bold md:text-2xl text-xl mb-3">Password</p>
                     <input type={inputType} className={"rounded-md md:h-12 h-10 md:w-3/4 w-full p-2 text-lg " + (validationTrigger ? "bg-red-200" : "bg-gray-200")} onChange={passwordTextOnChange} value={passwordText} />
                     {
                        passwordVisible
                           ? <FontAwesomeIcon icon={faEye} className="-ml-7 text-xl cursor-pointer" onClick={toggleShowPassword} />
                           : <FontAwesomeIcon icon={faEyeSlash} className="-ml-7 text-xl cursor-pointer" onClick={toggleShowPassword} />
                     }
                  </div>
               </div>
            </div>
            <div className="md:flex-col flex items-center justify-center md:mt-32 mt-8">
               <div className="flex flex-col justify-center items-center">
                  <svg className="md:block hidden bg-gray-400" width="2" height="150">
                     <line x1="20" y1="20" x2="20" y2="130"></line>
                  </svg>
                  <svg className="md:hidden block bg-gray-400" width="150" height="2">
                     <line x1="20" y1="20" x2="130" y2="20"></line>
                  </svg>
               </div>
               <div>
                  <p className="mx-3 text-2xl">or</p>
               </div>
               <div className="flex flex-col justify-center items-center md:mt-2">
                  <svg className="md:block hidden bg-gray-400" width="2" height="150">
                     <line x1="20" y1="20" x2="20" y2="130"></line>
                  </svg>
                  <svg className="md:hidden block bg-gray-400" width="150" height="2">
                     <line x1="20" y1="20" x2="130" y2="20"></line>
                  </svg>
               </div>
            </div>
            <div className="flex flex-col justify-center md:w-1/2 w-full">
               <div className="flex flex-col justify-center items-center">
                  <button className="flex items-center justify-center left-down-shadow rounded-3xl md:h-14 h-10 md:w-1/2 w-full border border-black text-white text-2xl mt-8 bg-blue-500 hover:bg-blue-600 cursor-pointer">
                     Continue with Google
                  </button>
                  <button className="flex items-center justify-center left-down-shadow rounded-3xl md:h-14 h-10 md:w-1/2 w-full border border-black text-white text-2xl mt-8 bg-blue-800 hover:bg-blue-900 cursor-pointer">
                     Continue with Facebook
                  </button>
                  {/* <button className="md:flex hidden items-center justify-center left-down-shadow rounded-3xl md:h-14 h-10 md:w-1/2 w-full border border-black text-white text-2xl mt-8 bg-white hover:bg-gray-100 cursor-pointer">
                     Continue with Apple
                  </button> */}
                  <button className={"md:hidden flex items-center justify-center pp-yellow-bg left-down-shadow rounded-3xl h-12 w-2/3 mt-8 border border-black text-black text-2xl hover:bg-yellow-600 cursor-pointer"} onClick={logInOnClick}>Login</button>
               </div>
            </div>
         </div>
         <div className="md:block hidden -mt-14">
            <button className={"flex items-center justify-center pp-yellow-bg left-down-shadow rounded-3xl h-14 w-1/5 mt-3 border border-black text-black text-2xl hover:bg-yellow-600 cursor-pointer"} onClick={logInOnClick}>Login</button>
         </div>
         <div className="mt-6">
            <a className="md:text-xl text-sm underline underline-offset-2">Forgot Email</a>
            <span className="mx-1 md:text-xl text-sm">|</span>
            <a className="md:text-xl text-sm underline underline-offset-2">Forgot Password</a>
            <span className="mx-1 md:text-xl text-sm">|</span>
            <a className="md:text-xl text-sm underline underline-offset-2">Can't Login?</a>
         </div>
         {(startLoad) &&
                <LoadingIndicator />
         }
      </div>
   )
}

export default Login;