import React from 'react';
import { Link } from "react-router-dom";
import SearchBar from "../components/SearchBar"

export default function UserSettings() {
    return(
        <div className="UserSettings">
            {/* Have to create settings page later to direct it back to the main settings page */}
            <Link to="/">
                <button>return</button>
            </Link>
            <div className="flex justify center">
                <h1>User Settings</h1>
            </div>
            <form id="form">
                <SearchBar type="search" placeholder="Search"/>
            </form>
            <div className="w-full">
                <Link to="/">
                    <button id="login">Change Login Details</button>
                </Link>
            </div> 
            <div className="w-full...">
                <Link to="/">
                    <button id="restart">Restart Application</button>
                </Link>
            </div>
            <div className="w-full...">
                <Link to="/">
                    <button id="notifications">Notifications</button>
                </Link>
            </div> 
            <div className="w-full...">
                <Link to="/">
                    <button id="report">Report a Bug</button>
                </Link>    
            </div>
            <div className="w-full...">
                <Link to="/">
                    <button id="feedback">Feedback</button>
                </Link>
            </div>
        </div>
        

    )
}