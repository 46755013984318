import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import Bear from '../assets/pp_avatars/bear.png';
import Cat from '../assets/pp_avatars/cat.png';
import Dog from '../assets/pp_avatars/dog.png';
import Octopus from '../assets/pp_avatars/octopus.png';
import Skunk from '../assets/pp_avatars/skunk.png';
import homePageLogo from '../assets/images/perfectplatelogo2.png';
import { useSessionStorage } from '../utils/useSessionStorage';
import SignupButton from '../components/buttons/SignupButton';

const Register = () => {
   const [validationMessage, setValidationMessage] = useState("");
   const [emailText, setEmailText] = useState("");
   const [passwordText, setPasswordText] = useState("");
   const [userAvatar, setUserAvatar] = useState("");
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [birthday, setBirthday] = useState("");

   const [inputType, setInputType] = useState("password");
   const [passwordVisible, setPasswordVisible] = useState(false);
   const [firstNameIsValid, setFirstNameIsValid] = useState(true);
   const [lastNameIsValid, setlastNameIsValid] = useState(true);
   const [emailIsValid, setEmailNameIsValid] = useState(true);
   const [passwordIsValid, setPasswordIsValid] = useState(true);


   const [user, setUser] = useSessionStorage("user", null)
   const [userRegFormIsVisible, setUserRegFormIsVisible] = useState(false);
   const [avatars, setAvatars] = useState([Bear, Cat, Dog, Octopus, Skunk]);

   const emailTextOnChange = (e) => {
      setEmailText(e.target.value)
   }
   const passwordTextOnChange = (e) => {
      setPasswordText(e.target.value)
   }
   const userAvatarOnChange = (imgPath) => {
      setUserAvatar(imgPath)
   }
   const firstNameOnChange = (e) => {
      setFirstName(e.target.value)
   }
   const lastNameOnChange = (e) => {
      setLastName(e.target.value)
   }
   const birthdayOnChange = (e) => {
      setBirthday(e.target.value)
   }

   const toggleShowPassword = () => {
      setPasswordVisible(!passwordVisible)
      if (passwordVisible) {
         setInputType("text")
      } else {
         setInputType("password")
      }
   }

   const submitRegistrationOnClick = () => {

      if (!passwordText) {
         setPasswordIsValid(false);
         setValidationMessage("Password is required. Please enter a value");
      } else if (passwordText.length < 8) {
         setPasswordIsValid(false);
         setValidationMessage("Password must contain at least 8 characters");
      } else if (!firstName) {
         setFirstNameIsValid(false);
         setValidationMessage("First name is required. Please enter a value");
      } else if (!lastName) {
         setlastNameIsValid(false);
      }



      axios.post(process.env.REACT_APP_BASE_API_URL + "api/Users/Register", {
         firstName,
         lastName,
         birthday,
         pictureUrl: "https://img.freepik.com/free-icon/user_318-563642.jpg?w=2000",
         email: emailText,
         password: passwordText,
         locale: ""
      }).then((response) => {
         const data = response.data;
         setUser(data);
         window.location.href = "/main";
      }).catch(() => {
         alert("Your profile was not able to be created at this time. Please try again.")
      })
   }

   return (
      <div className="px-10 w-full mx-auto bg-white absolute t-0 l-0" style={{ height: '110vh' }}>
         <div className="w-full mx-auto">
            <a href="/">
               <img className="mx-auto md:w-1/6 w-1/3 h-auto mt-10" src={homePageLogo} />
            </a>
         </div>
         <div className="md:flex md:flex-row flex-col w-full" id="registration-container">
            <div className='md:w-1/2 w-full'>
               <div className="w-full">
                  <p className="md:text-8xl text-6xl text-black font-bold header-font mt-10">SIGN UP</p>
                  <p className="md:text-2xl text-lg">Create a new account or <a href="/login" className="underline font-bold" style={{ color: "#F3A828" }}>Login</a></p>
               </div>
               <div id="user-registration">
                  <div className="flex">
                     <div className="my-1 w-1/2">
                        <p className="font-bold md:text-2xl text-xl mb-3">First Name</p>
                        <input type="text" className="bg-gray-200 rounded-md md:h-12 h-10 w-11/12 text-2xl px-2" onChange={firstNameOnChange} value={firstName} />
                     </div>
                     <div className="my-1 w-1/2">
                        <p className="font-bold md:text-2xl text-xl mb-3">Last Name</p>
                        <input type="text" className="bg-gray-200 rounded-md md:h-12 h-10 w-full text-2xl px-2" onChange={lastNameOnChange} value={lastName} />
                     </div>
                  </div>
                  <div>
                     <div className="my-1">
                        <p className="font-bold md:text-2xl text-xl mb-3">Email</p>
                        <input type="email" className="bg-gray-200 rounded-md md:h-12 h-10 w-full text-2xl px-2" onChange={emailTextOnChange} value={emailText} />
                     </div>
                  </div>
                  <div>
                     <p className="font-bold md:text-2xl text-xl mb-3">Create Password</p>
                     <div className="flex items-center justify-center">
                        <input type={inputType} className="bg-gray-200 rounded-md md:h-12 h-10 w-full text-2xl px-2" onChange={passwordTextOnChange} value={passwordText} />
                        {
                           passwordVisible
                              ? <FontAwesomeIcon icon={faEye} className="-ml-7 text-xl cursor-pointer" onClick={toggleShowPassword} />
                              : <FontAwesomeIcon icon={faEyeSlash} className="-ml-7 text-xl cursor-pointer" onClick={toggleShowPassword} />
                        }

                     </div>
                  </div>
                  <div>
                     <div className="my-1">
                        <p className="font-bold md:text-2xl text-xl mb-3">Birthday</p>
                        <input type="date" className="bg-gray-200 rounded-md md:h-12 h-10 w-full text-2xl px-2" onChange={birthdayOnChange} value={birthday} />
                     </div>
                  </div>
               </div>
            </div>
            <div className="flex flex-col justify-center md:w-1/2 w-full">
               <div className="flex justify-center md:mt-40 mt-10">
                  <div className="flex flex-col justify-center mt-2">
                     <svg version="1.1" id="line_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150px" height="5px">
                        <path className="path2" fill="#01a09e" strokeWidth="3" stroke="#000000" d="M0 0 l1120 0" />
                     </svg>
                  </div>
                  <div>
                     <p className="mx-3 text-2xl">or</p>
                  </div>
                  <div className="flex flex-col justify-center mt-2">
                     <svg version="1.1" id="line_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150px" height="5px">
                        <path className="path2" fill="#01a09e" strokeWidth="3" stroke="#000000" d="M0 0 l1120 0" />
                     </svg>
                  </div>
               </div>
               <div className="flex flex-col justify-center items-center">
                  <button className="flex items-center justify-center left-down-shadow rounded-3xl md:h-14 h-10 md:w-1/2 w-full border border-black text-white text-2xl mt-8 bg-blue-500 hover:bg-blue-600 cursor-pointer">
                     Continue with Google
                  </button>
                  <button className="flex items-center justify-center left-down-shadow rounded-3xl md:h-14 h-10 md:w-1/2 w-full border border-black text-white text-2xl mt-8 bg-blue-800 hover:bg-blue-900 cursor-pointer">
                     Continue with Facebook
                  </button>
                  {/* <button className="flex items-center justify-center left-down-shadow rounded-3xl h-14 w-1/2 border border-black text-black text-2xl mt-8 bg-white hover:bg-gray-100 cursor-pointer">
                     Continue with Apple
                  </button> */}
                  <div className="md:hidden block">
                     <SignupButton tailwindStyling={"flex items-center justify-center pp-yellow-bg left-down-shadow rounded-3xl h-12 px-4 w-full border border-black text-black text-2xl font-medium mt-8 hover:bg-yellow-600 cursor-pointer"}
                        onClick={submitRegistrationOnClick} />
                  </div>
               </div>
            </div>
         </div>
         <div className="md:block hidden">
            <SignupButton tailwindStyling={"flex items-center justify-center pp-yellow-bg left-down-shadow rounded-3xl h-14 w-1/5 border border-black text-black text-2xl font-bold mt-8 hover:bg-yellow-600 cursor-pointer"}
               onClick={submitRegistrationOnClick} />
         </div>
      </div>
   )
}

export default Register;