import React from "react";

const ContinueAsGuestButton = ({ tailwindStyling, loginRedirectUri, onClick }) => {
  return (
    <a className={tailwindStyling} href={loginRedirectUri} onClick={onClick}>
      Guest
    </a>
  );
};

export default ContinueAsGuestButton;