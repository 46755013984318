import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";

const SignupButton = ({tailwindStyling, signupRedirectUri, onClick}) => {
  // const { loginWithRedirect } = useAuth0();

  const logoutAction = () => {

  }

  return (
    <a
    className={tailwindStyling}
      href={signupRedirectUri}
      onClick={onClick}
    >
      Sign Up
    </a>
  );
};

export default SignupButton;