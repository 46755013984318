import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = ({tailwindStyling, loginRedirectUri}) => {
     // const { loginWithRedirect } = useAuth0();
     
     return (
          <a className={tailwindStyling} 
          href={loginRedirectUri}>
               Login
          </a>
     );
}

export default LoginButton;