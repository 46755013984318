import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { useSessionStorage } from '../utils/useSessionStorage';
import { useLocalStorage } from '../utils/useLocalStorage';
import NavBar from '../components/NavBar';
import FilterBar from '../components/menubars/FilterBar.jsx'
import CuisineBar from '../components/menubars/CuisineBar.jsx'
import Map from '../components/Map';
import UIOverlay from '../components/UIOverlay'
import LoadingIndicator from '../components/LoadingIndicator';


const Main = () => {

    let [isOpen, setIsOpen] = useState(true)
    const [map, setMap] = useState(null)
    const [userCoords, setUserCoords] = useState({
         lat: 29.42,
         lng: 98.49
    })
    const [user, setUser] = useSessionStorage("user", null)
    const [userLocation, setUserLocation] = useLocalStorage("userLocation", null)
    const [restaurants, setRestaurants] = useState([])
    const [startLoad, setStartLoad] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(12);
    const [moveToUser, setMoveToUser] = useState(false);

    //Filters
    const [filterValues, setFilterValues] = useState({
         applyFilters: false,
         searchRadius: 5,
         rating: 0,
         price: 0,
         zoomLevel: 0,
         latitude: 0,
         longitude: 0,
         // match: 0
    });

    const handleFilterChange = (filterName, value) => {
         setFilterValues((prevFilters) => ({
              ...prevFilters,
              [filterName]: value,
         }))
    }

    const applyRestaurantFilters = () => {
         setStartLoad(true);
         setMoveToUser(true);

         axios.get(process.env.REACT_APP_BASE_API_URL + "api/Restaurants", { 
              params: { 
                   userLatitude: filterValues.latitude, 
                   userLongitude: filterValues.longitude,
                   price: filterValues.price,
                   rating: filterValues.rating, 
                   radius: filterValues.searchRadius } })
              .then((response) => {
                   let data = response.data;
                   setRestaurants(data)
                   setStartLoad(false);
                   const newZoomLevel = Math.round(14 - Math.log2(filterValues.searchRadius));
                   setZoomLevel(newZoomLevel);
                   setMoveToUser(false);

              })
              .catch((err) => {
                   alert("Could not retreive restaurants at this time.")
                   console.log(err)
              })
    }

    function onMapLoad(userCoords = {}) {
         let latitude = userCoords.lat || userLocation.lat;
         let longitude = userCoords.lng || userLocation.lng;

         setFilterValues((prevFilters) => ({
              ...prevFilters,
              ['latitude']: latitude,
              ['longitude']: longitude
         }))


         applyRestaurantFilters();

    }

    return(

        <div>
               {<NavBar />} {/* Rename nav bar to overlay */}
               {<FilterBar filterValues={filterValues} onFilterChange={handleFilterChange} applyRestaurantFilters={applyRestaurantFilters}/>}
               {<CuisineBar />}

               <div className={`w-full ${startLoad ? 'blur-sm' : ''}`} >
                    {
                        <div>
                            <UIOverlay restaurants={restaurants} />
                            <Map restaurants={restaurants} onMapLoad={onMapLoad} filterValues={filterValues} zoomLevel={zoomLevel} moveToUser={moveToUser}/> {/*Rename to main and rename home to "landing" */}
                        </div>
                    }
               </div>
               {(startLoad) &&
                    <LoadingIndicator />
               }
          </div>


    )

}
export default Main;