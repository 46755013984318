import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import LoginButton from '../components/buttons/LoginButton';
import SignupButton from '../components/buttons/SignupButton';
import ContinueAsGuestButton from '../components/buttons/ContinueAsGuestButton';
import BottomDrawer from '../components/BottomDrawer';
import Map from '../components/Map';
import UIOverlay from '../components/UIOverlay'

import plate from "../assets/images/plate.png"
import bgImage from "../assets/images/homepageBackground.png"
import homePageLogo from '../assets/images/perfectplatelogo2.png'
import "../styles/Home.css"
import { useSessionStorage } from '../utils/useSessionStorage';
import { useLocalStorage } from '../utils/useLocalStorage';
import NavBar from '../components/NavBar';
import FilterBar from '../components/menubars/FilterBar.jsx'
import CuisineBar from '../components/menubars/CuisineBar.jsx'
import Main from '../views/Main.jsx';

const Home = () => {

     const [user, setUser] = useSessionStorage("user", null)
     const [showMap, setShowMap] = useState(false)


     const mapTransition = (value) => {
          console.log(value)
          setShowMap(true)

     }

     return (
          
          <div>
               { showMap && <Main /> }
               { !showMap && 
                    <div className="w-full">
                         {!user &&
                              <div style={{
                                   backgroundImage: `url(${bgImage})`,
                                   backgroundRepeat: 'no-repeat',
                                   backgroundSize: 'cover',
                                   backgroundPosition: 'center'
                              }} id='welcome-page-bg'>
                                   <div className="w-10/12 mx-auto flex-col items-center" id="container">
                                        <div id="welcome-page-overlay">
                                             <div className="w-full mx-auto mt-10">
                                                  <img className="mx-auto" src={homePageLogo} />
                                             </div>
                                             <div className='md:flex md:mx-0 mx-auto'>
                                                  <div className="w-full mt-5 z-10 md:z-0 relative" id="welcome-page-content">
                                                       <div className="w-full">
                                                            <p className="md:text-9xl text-5xl md:text-left text-center text-white font-bold header-font">Eating out has never been easier.</p>
                                                       </div>
                                                       <p className='md:text-xl text-lg md:text-left text-center md:w-3/4 z-20 md:z-0'>By learning your food preferences, we'll predict how much you'll enjoy your next meal<span className="font-bold"> — before you even try it!</span> <span className="hidden md:inline">Your Perfect Plate is one step away.</span></p>
                                                       <p className="md:hidden mt-5 text-xl text-center">Your Perfect Plate is one step away.</p>
                                                  </div>
                                                  <div className="flex flex-col justify-center md:z-0 -z-10">
                                                       <img src={plate} className="animate-bounce absolute top-4/5 md:relative -bottom-12 h-auto left-0 -z-10 md:z-0" />
                                                  </div>
                                             </div>
                                             <div className="md:flex md:mx-0 mx-auto hidden">
                                                  <LoginButton tailwindStyling={"flex items-center justify-center bg-red-500 rounded-md h-14 w-48 mr-8 border border-black rounded-3xl left-down-shadow text-black font-bold text-2xl md:mt-5 hover:bg-red-600 cursor-pointer"} loginRedirectUri={"/login"} />
                                                  <SignupButton tailwindStyling={"flex items-center justify-center pp-yellow-bg left-down-shadow mr-8 rounded-3xl h-14 w-48 border border-black text-black text-2xl font-bold mt-5 hover:bg-yellow-600 cursor-pointer"} signupRedirectUri={"/register"} />
                                                  <ContinueAsGuestButton tailwindStyling={"flex items-center justify-center bg-red-500 left-down-shadow rounded-3xl h-14 w-48 border border-black text-black text-2xl font-bold mt-5 hover:bg-yellow-600 cursor-pointer"} onClick={() => mapTransition(true)} /> {/* Added onClick event to show the map */}
                                             </div>
                                             <div className="md:hidden flex flex-col justify-center items-center w-full mt-14">
                                                  <LoginButton tailwindStyling={"flex items-center justify-center bg-red-500 rounded-md h-14 w-48 border border-black rounded-3xl left-down-shadow text-black font-bold text-2xl hover:bg-red-600 cursor-pointer"} loginRedirectUri={"/login"} />
                                                  <SignupButton tailwindStyling={"flex items-center justify-center pp-yellow-bg left-down-shadow rounded-3xl h-14 w-48 border border-black text-black text-2xl font-bold mt-5 hover:bg-yellow-600 cursor-pointer"} signupRedirectUri={"/register"} />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         }
                              
                    </div>
               }
          </div>
     )
}

export default Home;