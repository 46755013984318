import React, { useState } from 'react';
import "../../styles/components/mapMarker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Marquee from 'react-fast-marquee';

const outerColors = {
    "green": "outer-green",
    "purple": "outer-purple",
    "orange": "outer-orange"
}

const innerColors = {
    "green": "inner-green",
    "purple": "inner-purple",
    "orange": "inner-orange"
}

const details = {
    ranking: "int",
    name: "string",
    priceRange: "string",
    cuisineType: "string",
    rating: "int",
    distance: "int"
}

const MapMarker = ({ restaurant, ranking }) => { // details, ranking, mode = "collapsed or expanded"

    const [mode, setMode] = useState("collapsed");

    const selectOuterColor = (ranking) => {
        if (ranking >= 90) {
            return outerColors.green;
        } else if (ranking >= 50) {
            return outerColors.purple;
        } else {
            return outerColors.orange;
        }
    }

    const selectInnerColor = (ranking) => {
        if (ranking >= 90) {
            return innerColors.green;
        } else if (ranking >= 50) {
            return innerColors.purple;
        } else {
            return innerColors.orange;
        }
    }

    const handleMarkerClick = (event) => {
        console.log(event);
        let id = event.target.id.split("-")[2];
        console.log(id)
        let restaurantCard = document.getElementById(`restaurant-card-${id-1}`);

        restaurantCard.scrollIntoView({ behavior: "smooth", block: "center" });

    }

    return (
        <>
            {
                mode === "collapsed" &&

                <div className={selectOuterColor(ranking) + " marker-collapsed-outer"} onMouseOver={() => setMode("expanded")}>
                    <div className={selectInnerColor(ranking) + " marker-collapsed-inner"}>
                        <p className="marker-text">{ranking}</p>
                    </div>
                </div>
            }
            {
                mode === "expanded" &&
                <div className="flex">
                    <div className={selectOuterColor(ranking) + " marker-expanded-left-outer"} onMouseLeave={() => setMode("collapsed")}>

                        <div className={selectInnerColor(ranking) + " marker-expanded-left-inner"}>
                            <p className="marker-text" onClick={(e) => handleMarkerClick(e)} id={`expanded-marker-${ranking}`}>{ranking}</p>
                        </div>
                    </div>
                    <div className={selectOuterColor(ranking) + " marker-expanded-right"}>
                        <div className="marker-expanded-name">
                            <Marquee
                                speed={70}
                                play={restaurant.name.length > 20}
                            >
                                <p className="font-bold">{restaurant.name}</p>
                            </Marquee>

                        </div>
                        <div className="marker-expanded-details">
                            <p><span className="text-sm xl:text-base font-normal text-gray-500">{restaurant.priceRange.length > 0 ? restaurant.priceRange : "?"}</span> · <span className="text-sm xl:text-base font-normal text-gray-500">{restaurant.cuisineType}</span> · <span className="text-sm xl:text-base font-normal text-gray-500">{restaurant.rating ? restaurant.rating : "?"}</span> <FontAwesomeIcon icon={faStar} /> <span className="text-sm xl:text-base font-normal text-gray-500">({restaurant.ratingCount})</span> · <span className="text-sm xl:text-base font-normal text-gray-500">{restaurant.distance}0</span><span> mi</span></p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MapMarker;