import React, { useState } from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faCompass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { renderMenu } from '../../utils/FilterBar/renderFilterMenu';

const FilterBar = ({ filterValues, onFilterChange, applyRestaurantFilters }) => {
   const [selectedFilter, setSelectedFilter] = useState(null);

   const handleClick = (filter) => {
      if (selectedFilter === filter) {
         setSelectedFilter(null);
      } else {
         setSelectedFilter(filter);
      }
   };

   // const filters = ['Match', 'Price', 'Rating', 'Meal', 'Hours', 'Distance'];
   const filters = ['Match', 'Price', 'Rating', 'Distance'];

   return (
      <div className="absolute z-30 top-24 left-6">
         <div className="flex">
            {filters.map((filter, index) => (
               <div key={index}>
                  <div
                     className="relative bg-gray-300 hover:bg-gray-400 cursor-pointer transition-all duration-200 rounded-full py-1 px-5 mr-5 shadow-lg"
                     onClick={() => handleClick(filter)}
                  >
                     {filter === 'Match' && <FontAwesomeIcon icon={faThumbsUp} className="mr-2" />}
                     {filter === 'Price' && <FontAwesomeIcon icon={faDollarSign} className="mr-2" />}
                     {filter === 'Rating' && <FontAwesomeIcon icon={faStar} className="mr-2" />}
                     {filter === 'Distance' && <FontAwesomeIcon icon={faCompass} className="mr-2" />}
                     <span>{filter}</span>
                     {filter !== 'Apply' && (
                        <span className="relative ml-2">
                           <FontAwesomeIcon icon={faCaretDown} />
                        </span>
                     )}
                  </div>
                  {selectedFilter === filter && renderMenu(filter, filterValues, onFilterChange)}
               </div>
            ))}
            <div>
               <div
                  className="relative bg-gray-300 hover:bg-gray-400 cursor-pointer transition-all duration-200 rounded-full py-1 px-5 mr-5 shadow-lg"
                  onClick={applyRestaurantFilters}
               >
                  <span className=" font-semibold">Apply Filters</span>
               </div>
            </div>
         </div>
      </div>
   );
};

export default FilterBar;