import React from 'react'
import logo from "../assets/images/PerfectPlateLogo.png"

const modalStyle = {
   top: '50%',
   left: '50%',
   width: '300px',
   marginTop: '-75px',
   marginLeft: '-150px',
   zIndex: 13
}

const LoadingIndicator = () => {
   return (
      <div className="absolute" style={modalStyle}>
         <img src={logo} className=" w-4/12 h-auto animate-spin mx-auto"/>
         <p className="w-full text-center text-xl text-gray-500 ml-2 mt-2">Please wait ...</p>
      </div>
   )
}

export default LoadingIndicator;