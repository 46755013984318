import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'

const SearchBar = ({ type, placeholderText }) => {
   return (
      <div className='w-full flex ml-10 z-10'>
         <div className="flex justify-center bg-white h-10 w-10 rounded-tl-full rounded-bl-full border-r shadow-lg">
            {
               type === "location"
               &&
               <div className="h-10 w-8 flex justify-center items-center">
                  <FontAwesomeIcon icon={faLocationDot} className="h-5 ml-1" />
               </div>
            }
            {
               type === "search"
               &&
               <div className="h-10 w-8 flex justify-center items-center">
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="h-5 ml-1" />
               </div>
            }
         </div>

         {
            type === "location"
            &&
            <div className="w-1/2">
               <input type="search" className="w-full h-10 px-2 focus:outline-none rounded-tr-full rounded-br-full shadow-lg " placeholder={placeholderText} />
            </div>
         }
         {
            type === "search"
            &&
            <div className="w-1/2">
               <input type="search" className="w-full h-10 px-2 focus:outline-none rounded-tr-full rounded-br-full shadow-lg" placeholder={placeholderText} />
            </div>
         }
         {/* <div className="bg-white h-12 w-8 rounded-tr-xl rounded-br-xl"></div> */}


      </div>
   )
}

export default SearchBar;