import React, { useMemo, useState, useEffect } from 'react'
import { useSessionStorage } from '../utils/useSessionStorage';

const modalStyle = {
   top: '50%',
   left: '50%',
   width: '300px',
   marginTop: '-75px',
   marginLeft: '-150px',
   zIndex: 13
}

const windowHeight = window.innerHeight;

const modalBgStyle = {
   position: "absolute",
   top: '56px',
   width: '100vw',
   height: windowHeight - 56,
   zIndex: 10,
   backgroundColor: "#919191",
   opacity: 0.5
};

const LocationPermission = () => {
   const locationAccess = useSessionStorage("locationAccess", "");

   useEffect(() => {
      navigator.geolocation.getCurrentPosition((position) => {
         console.log(position)
      })
   }, [])

   return (
      <>
         <div style={modalBgStyle}></div>
         <div className="absolute w-12 bg-slate-100 border-2 border-b rounded-xl shadow-lg" style={modalStyle}>
            <h3 className="text-2xl text-gray-500 font-bold text-center">Location Permission</h3>
            <p className="text-center text-blue-500 text-lg p-3">Please allow "PerfectPlate" to access your location</p>
            <div className="flex justify-center mt-3">
               <button 
                  onClick={() => {window.location.reload()}}
                  className="border-2 bg-blue-500 text-white rounded-md p-2 w-3/4 mb-2 hover:bg-blue-400">
                  I've Enabled It!
               </button>
            </div>
         </div>
      </>

   )
};

export default LocationPermission;