import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function renderMenu(filter, filterValues, onFilterChange) {

    // const [value, setValue] = useState(1);

    // const handleChange = (event) => {
    //     setValue(event.target.value);
    // };

    const handleInputChange = (event, filterName) => {
        const value = parseInt(event.target.value);
        onFilterChange(filterName, value);
    }

    const price = ['All', '>$$', '>$$$', '$$$$']

    return (
        <div className="absolute bg-gray-100 w-34 rounded-br-xl rounded-bl-xl rounded-tr-xl rounded-tl-none p-2 mt-2">
            {
                filter == "Match"
                &&
                <div>
                    <div className="flex text-xs mb-3">
                        <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                        A Plate Made in Heaven!
                    </div>
                    <div className="flex text-xs mb-3">
                        <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                        Expand Your Horizons!
                    </div>
                    <div className="flex text-xs mb-3">
                        <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                        Don't Even Bother...
                    </div>
                </div>
            }
            {
                filter == "Price"
                &&
                <div>
                    <div className="flex items-center justify-center w-64 mx-auto flex-col">
                    <div className="text-xl" id="distance-display">{price[filterValues.price - 1]}</div>
                        <div className="flex justify-around items-center">
                            <div className="w-4 text-center">All</div>
                            <input
                                type="range"
                                min={1}
                                max={4}
                                value={filterValues.price || 1}
                                onChange={(event) => {
                                    handleInputChange(event, 'price')
                                }}
                                className="flex-grow mx-4 appearance-none h-2 rounded bg-gray-300 focus:outline-none focus:bg-blue-500"
                            />
                            <div className="w-4 text-center">$$$$</div>
                        </div>
                    </div>
                </div>
            }
            {
                filter == "Rating"
                &&
                <div>
                    <div className="flex items-center justify-center w-64 mx-auto flex-col">
                    <div className="text-xl" id="distance-display">{filterValues.rating}</div>
                        <div className="flex justify-around items-center">
                            <div className="w-4 text-center">1</div>
                            <input
                                type="range"
                                min={0}
                                max={5}
                                value={filterValues.rating || 0}
                                onChange={(event) => {
                                    handleInputChange(event, 'rating')
                                }}
                                className="flex-grow mx-4 appearance-none h-2 rounded bg-gray-300 focus:outline-none focus:bg-blue-500"
                            />
                            <div className="w-4 text-center">5</div>
                        </div>
                    </div>
                </div>
            }
            {
                filter == "Meal"
                &&
                <div>
                    <div className="flex text-xs mb-3">
                        <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                        Breakfast
                    </div>
                    <div className="flex text-xs mb-3">
                        <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                        Brunch
                    </div>
                    <div className="flex text-xs mb-3">
                        <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                        Lunch
                    </div>
                    <div className="flex text-xs mb-3">
                        <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                        Happy Hour
                    </div>
                    <div className="flex text-xs mb-3">
                        <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                        Dinner
                    </div>
                    <div className="flex text-xs mb-3">
                        <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                        Late Night
                    </div>
                </div>
            }
            {
                filter == "Hours"
                &&
                <div className=" w-72">
                    <div className="flex flex-col justify-center items-center border-b border-b-black">
                        <div className="flex text-xs mb-3">
                            <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                            Open Now
                        </div>
                        <div className="flex text-xs mb-3">
                            <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                            Open 24 Hours
                        </div>
                    </div>
                    <div className="text-sm text-center my-2">Open On</div>
                    <div className="flex">
                        <div className="flex flex-col items-start w-1/3 border-r border-r-black">
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                Sunday
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                Monday
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                Tuesday
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                Wednesday
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                Thursday
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                Friday
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                Saturday
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-start w-1/3 ml-5">
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                Noon
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                1
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                2
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                3
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                4
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                5
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                6
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                7
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                8
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                9
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                10
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                10
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-start w-1/3">
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                Noon
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                1
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                2
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                3
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                4
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                5
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                6
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                7
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                8
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                9
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                10
                            </div>
                            <div className="flex text-xs mb-3">
                                <input type="checkbox" className="mr-1" onChange={{}} checked={{}} />
                                10
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                filter == "Distance"
                &&
                <div>
                    <div className="flex items-center justify-center w-64 mx-auto flex-col">
                    <div className="text-xl" id="distance-display">{filterValues.searchRadius}</div>
                        <div className="flex justify-around items-center">
                            <div className="w-8 text-center">1</div>
                            <input
                                type="range"
                                min={1}
                                max={25}
                                value={filterValues.searchRadius || 5}
                                onChange={(event) => {
                                    handleInputChange(event, 'searchRadius')
                                }}
                                className="flex-grow mx-4 appearance-none h-2 rounded bg-gray-300 focus:outline-none focus:bg-blue-500"
                            />
                            <div className="w-8 text-center">25</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};