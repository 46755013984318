import React, { useState } from 'react';
import RestaurantCardScroller from './cards/RestaurantCardScroller';

const UIOverlay = ({ restaurants }) => {

   function parseImageUrl(imageUrl) {
      if(!imageUrl.includes("|")){
         return imageUrl;
      }

      return imageUrl.split("|")[0];

   }

   return (
      <div className="z-20">
         <RestaurantCardScroller>
            {restaurants.length > 0 &&
               restaurants.map((item, index) => {
                  return (
                     <RestaurantCardScroller.Item
                        image={parseImageUrl(item.image)}
                        name={item.name}
                        rating={item.rating || index}
                        priceRange={item.priceRange || ""}
                        cuisineType={item.cuisineType}
                        cuisineCategory={item.cuisineCategory}
                        ratingCount={item.reviews || 0}
                        distance={item.distance}
                        match={item.match || "?"}
                        key={index}
                        index={index}
                        id={"restaurant-card-" + index} />
                  )
               })
            }
         </RestaurantCardScroller>
      </div>
   )
}

export default UIOverlay;