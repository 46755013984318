import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

const windowHeight = window.innerHeight;
const drawerOpen = windowHeight - (windowHeight * 1 / 3);
const drawerClosed = windowHeight - (windowHeight * 18 / 19);

const BottomDrawer = (props) => {
     const [isOpen, setIsOpen] = useState(false);

     const onClickDrawerArrow = () => {
          setIsOpen(!isOpen)
     }

     return (
          <div
               className="transition-all ease-in-out duration-500 rounded-tl-3xl rounded-tr-3xl bg-white absolute bottom-0 w-full border-2"
               style={{ height: (isOpen ? drawerOpen : drawerClosed) }} // closed is 11/12 and open is 1/3 
               id="bottomDrawer">
               <div className='w-100 flex justify-center'>
                    {
                         isOpen
                              ? <FontAwesomeIcon icon={faAngleDown} className="h-8 cursor-pointer" onClick={ onClickDrawerArrow }/>
                              : <FontAwesomeIcon icon={faAngleUp} className="h-8 cursor-pointer" onClick={ onClickDrawerArrow }/>
                    }
               </div>

               {
                    // isOpen
                    // &&
                    <div>
                         {props.children}
                    </div>
               }




               {/* <div className="bg-white flex justify-around" id="cuisines-bar">
                    <p className=' border-gray-700 border-l-2 border-r-2 px-6'>Italian</p>
                    <p className=' border-gray-700 border-l-2 border-r-2 px-6'>Mexican</p>
                    <p className=' border-gray-700 border-l-2 border-r-2 px-6'>American</p>
               </div> */}
               {/* <div>
                    <table className='border border-slate-300 table-auto w-full bg-white'>
                         <thead>
                              <tr>
                                   <th>Items</th>
                                   <th>Name</th>
                                   <th>Avg Cost</th>
                                   <th>Distance</th>
                              </tr>
                         </thead>
                         <tbody>
                              <tr>
                                   <td className='border border-slate-300 text-center py-1'>14</td>
                                   <td className='border border-slate-300 text-center py-1'> La Fusion</td>
                                   <td className='border border-slate-300 text-center py-1'>$14.35</td>
                                   <td className='border border-slate-300 text-center py-1'>5.4mi</td>
                              </tr>
                              <tr>
                                   <td className='border border-slate-300 text-center py-1'>9</td>
                                   <td className='border border-slate-300 text-center py-1'> Le Colonial</td>
                                   <td className='border border-slate-300 text-center py-1'>$17.05</td>
                                   <td className='border border-slate-300 text-center py-1'>5.7mi</td>
                              </tr>
                              <tr>
                                   <td className='border border-slate-300 text-center py-1'>8</td>
                                   <td className='border border-slate-300 text-center py-1'> Sushirrito</td>
                                   <td className='border border-slate-300 text-center py-1'>$11.45</td>
                                   <td className='border border-slate-300 text-center py-1'>4.1mi</td>
                              </tr>
                              <tr>
                                   <td className='border border-slate-300 text-center py-1'>5</td>
                                   <td className='border border-slate-300 text-center py-1'> Chaya</td>
                                   <td className='border border-slate-300 text-center py-1'>$12.75</td>
                                   <td className='border border-slate-300 text-center py-1'>5.9mi</td>
                              </tr>
                              <tr>
                                   <td className='border border-slate-300 text-center py-1'>3</td>
                                   <td className='border border-slate-300 text-center py-1'> Seven Fifty</td>
                                   <td className='border border-slate-300 text-center py-1'>$11.21</td>
                                   <td className='border border-slate-300 text-center py-1'>5.4mi</td>
                              </tr>
                         </tbody>
                    </table>
               </div> */}
          </div>

     )
}

export default BottomDrawer;