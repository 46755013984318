import React, { useState, useEffect } from 'react';
import { useSessionStorage } from '../utils/useSessionStorage';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom';
import logo from "../assets/images/PerfectPlateLogo.png"
import homePageLogo from "../assets/images/perfectplatelogo2.png"
import "../styles/components/navbarstyles.css"
import SearchBar from './SearchBar';
import RestaurantCardScroller from './cards/RestaurantCardScroller'
const navigation = [
     { name: 'Preferences', href: '#', current: true },
     { name: 'Restaurant History', href: '#', current: false },
     /*{ name: 'Report a Bug', href: '/ReportBug', current: false },*/
     { name: 'Support', href: '/Support', current: false },
     { name: 'User Settings', href: '/UserSettings', current: false },
]

const classNames = (...classes) => {
     return classes.filter(Boolean).join(' ')
}


const NavBar = () => {
     const [user, setUser] = useSessionStorage("user", null);

     return (
               <Disclosure as="nav" className="bg-transparent z-50 absolute w-full mt-8">
                    {({ open }) => (
                         <>
                              <div className=" mx-auto px-2">
                                   <div className="relative flex items-center h-14">
                                        <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                             {
                                                  open ? (
                                                       <XIcon className="block h-7 w-7" aria-hidden="true" />
                                                  ) : (
                                                       <MenuIcon className="block h-7 w-7" aria-hidden="true" />
                                                  )
                                             }
                                        </Disclosure.Button>
                                        <div className="flex items-center justify-center sm:items-stretch sm:justify-start" id="">
                                             <div className="flex ">
                                                  {/* <Link to="/" className="text-red-500 ml-1 text-4xl font-bold header-font"> */}
                                                       <img className="w-28 h-auto transition-all duration-200 hover:w-32 hover:animate-spin" src={homePageLogo} />
                                                  {/* </Link> */}
                                             </div>
                                        </div>
                                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                             <SearchBar type="search" placeholderText={"Search for restaurants, cuisine, diet"} />
                                        </div>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                             {/* Profile dropdown */}
                                             {!user &&
                                                  <Menu as="div" className="relative">
                                                       <div>
                                                            <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-black shadow-xl">
                                                                 <span className="sr-only">Open user menu</span>
                                                                 <img
                                                                      className="h-10 w-10 rounded-full shadow-lg"
                                                                      src={user?.pictureUrl || 'https://cdn.iconscout.com/icon/free/png-512/free-avatar-human-man-profile-auto-user-30483.png?f=avif&w=512'}
                                                                      referrerPolicy="no-referrer"
                                                                 />
                                                            </Menu.Button>
                                                       </div>
                                                       <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                       >
                                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-black ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                                                                 <Menu.Item>
                                                                      {({ active }) => (
                                                                           <a
                                                                                href="/register"
                                                                                className={classNames(active ? 'bg-gray-500' : '', 'block px-4 py-2 text-sm text-white')}
                                                                           >
                                                                                Sign Up
                                                                           </a>
                                                                      )}
                                                                 </Menu.Item>
                                                                 <Menu.Item>
                                                                      {({ active }) => (
                                                                           <a
                                                                                href="/login"
                                                                                className={classNames(active ? 'bg-gray-500' : '', 'block px-4 py-2 text-sm text-white')}
                                                                           >
                                                                                Log In
                                                                           </a>
                                                                      )}
                                                                 </Menu.Item>
                                                            </Menu.Items>
                                                       </Transition>
                                                  </Menu>
                                             }
                                             {user &&
                                                  <Menu as="div" className="relative">
                                                       <div>
                                                            <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-black shadow-xl">
                                                                 <span className="sr-only">Open user menu</span>
                                                                 <img
                                                                      className="h-10 w-10 rounded-full shadow-lg"
                                                                      src={user.pictureUrl}
                                                                      referrerPolicy="no-referrer"
                                                                 />
                                                            </Menu.Button>
                                                       </div>
                                                       <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                       >
                                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-black ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                                                                 <Menu.Item>
                                                                      {({ active }) => (
                                                                           <a
                                                                                href="#"
                                                                                className={classNames(active ? 'bg-gray-500' : '', 'block px-4 py-2 text-sm text-white')}
                                                                           >
                                                                                Your Profile
                                                                           </a>
                                                                      )}
                                                                 </Menu.Item>
                                                                 <Menu.Item>
                                                                      {({ active }) => (
                                                                           <a
                                                                                href="/UserSettings"
                                                                                className={classNames(active ? 'bg-gray-500' : '', 'block px-4 py-2 text-sm text-white')}
                                                                           >
                                                                                Settings
                                                                           </a>
                                                                      )}
                                                                 </Menu.Item>
                                                                 <Menu.Item>
                                                                      {({ active }) => (
                                                                           <a
                                                                                href="#"
                                                                                onClick={() => { setUser(null); window.location.href = "/"; }}
                                                                                className={classNames(active ? 'bg-gray-500' : '', 'block px-4 py-2 text-sm text-white')}
                                                                           >
                                                                                Sign out
                                                                           </a>
                                                                      )}
                                                                 </Menu.Item>
                                                            </Menu.Items>
                                                       </Transition>
                                                  </Menu>
                                             }
                                        </div>
                                   </div>
                              </div>
                              <Disclosure.Panel as="div" className="relative">
                                   <div className="absolute top-0 left-0 w-64 shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50" id="hamburger-menu">
                                        {navigation.map((item) => (
                                             <Transition
                                                  key={item.name}
                                                  as={Fragment}
                                                  enter="transition ease-out duration-100"
                                                  enterFrom="transform opacity-0 scale-95"
                                                  enterTo="transform opacity-100 scale-100"
                                                  leave="transition ease-in duration-75"
                                                  leaveFrom="transform opacity-100 scale-100"
                                                  leaveTo="transform opacity-0 scale-95"
                                             >
                                                  <Disclosure.Button
                                                       key={item.name}
                                                       as="a"
                                                       href={item.href}
                                                       className={classNames(
                                                            /*item.current ? 'bg-gray-900 text-white' :*/ 'text-black hover:bg-red-500 hover:text-white',
                                                            'block px-3 py-5 rounded-md text-base font-medium'
                                                       )}
                                                       aria-current={item.current ? 'page' : undefined}
                                                  >
                                                       {item.name}
                                                  </Disclosure.Button>
                                             </Transition>
                                        ))}
                                   </div>
                              </Disclosure.Panel>
                         </>
                    )}
               </Disclosure>

     )
}

export default NavBar;