import {React, useState, useEffect } from "react"; // V18
import { Route, Routes } from "react-router-dom"; // V6
import Home from './views/Home'
import NavBar from "./components/NavBar";
import UserSettings from "./views/UserSettings";
import Login from "./views/Login";
import Register from "./views/Register";
import Support from "./views/Support"
import Main from "./views/Main"

// import { ProtectedRoute } from "./components/protected-route";
// import { Loader } from "./components/loader";
// import { Footer } from "./components/footer";
// import { MobileNavBar } from "./components/mobile-nav-bar";
// import { NavBar } from "./components/nav-bar";
// import { AdminPage } from "./pages/admin";
// import { Home } from "./pages/home";
// import { NotFound } from "./pages/not-found";
// import { Profile } from "./pages/profile";
// import { ProtectedPage } from "./pages/protected";
// import { PublicPage } from "./pages/public";

const App = () => {
  // const { isLoading, isAuthenticated, user } = useAuth0();
  

  // useEffect(()=> {
  //   if(isAuthenticated){
  //     setUserInfo(...userInfo, user)
  //   }
  // }, [user])


  // if (isLoading) {
  //   return (
  //     <div className="page-layout">
  //       {/* <Loader /> */}
  //     </div>
  //   );
  // }
  // if(isAuthenticated){
  //   setUserInfo(user)
  // }

  /********* NOTES **********/
  // components folder: for view/page fragments that can be reutilized 
  // views folder: for individual 'web pages'
  // utils folder: for react hooks
  // styles folder: for all css 
  // add sub folders as necessary 


  return (
    <div className="page-layout">
      <div className="page-layout__content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/usersettings" element={<UserSettings />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/support" element={<Support />} />
          <Route path="/main" element={<Main />} />

          {/* <Route
            path="/profile"
            element={<ProtectedRoute component={Profile} />}
          /> */}
          {/* <Route path="/public" element={<PublicPage />} />
          <Route
            path="/protected"
            element={<ProtectedRoute component={ProtectedPage} />}
          />
          <Route
            path="/admin"
            element={<ProtectedRoute component={AdminPage} />}
          />
          <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default App;