import React from 'react';

const RankingMarker = ({text, outerColor, innerColor}) => {
   return (
      <>
         <div className={outerColor + " flex justify-center items-center rounded-tl-full rounded-tr-full rounded-br-full m-2"} style={{width: "60%", height: "60%"}}>
            <div className={innerColor + " flex justify-center items-center rounded-full"} style={{width: "80%", height: "80%"}}>
               <p className="text-sm font-normal">{text}</p>
            </div>
         </div>
      </>
   )
}

export default RankingMarker;